const columnListBlock = {
    count: null,
    block: null,

    getCount: function() {
        if (this.count) {
            return Number(this.count);
        }
    },

    setCount: function() {
        if (this.blockHasDataset()) {
            this.count = this.block.dataset.count;
        }
    },

    setBlock: function(block) {
        if (block) {
            this.block = document.querySelector(`.${block}`);
        }
    },

    setBlockType: function() {
        if (this.blockHasDataset()) {
            this.block.dataset.items = this.count;
        }
    },

    blockHasDataset() {
        if (this.block && this.block.dataset) {
            return true;
        }
    },

    init: function(block) {
        this.setBlock(block);
        this.setCount();
        this.setBlockType();
    }
};

export default columnListBlock;